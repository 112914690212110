<template>
    <div id="homepage">
            <v-card flat class="image">
                <center>
                    <img class="logo" width="100%" height="100%" src="../assets/Login_Logo.png" />
                </center>
            </v-card> 
        <UpdateLogs></UpdateLogs>
    </div>
</template>

<script>
import UpdateLogs from '@/components/UpdateLogs.vue';
export default {
    components:{
        UpdateLogs
    },
};
</script>

<style>
.image{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30%;
    width: 90%;
}
.logo {
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
}

.logo:hover {
  transform: scale(1.1);
}
</style>
