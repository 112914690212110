/* eslint-disable no-console */

import { register } from 'register-service-worker'
import Swal from 'sweetalert2'
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      // console.log('New content is available; please refresh.')
      if (sessionStorage.getItem('appJustUpdated') === 'true') {
        sessionStorage.removeItem('appJustUpdated');
        return;
      }
      Swal.fire({
        icon: 'info',
        title: 'システム更新',
        html: 
          `
            <span>新しいコンテンツが適用されます。</span>
            <br>
            <span>続行するには[OK]をクリックしてください。</span>
          `,
        allowOutsideClick: false,
        confirmButtonColor: '#1976D2'
      }).then(() => {
        sessionStorage.setItem('appJustUpdated', 'true');
        
        // Unregister the service worker and reload the page
        registration.unregister().then(() => {
          window.location.reload(true);
        });
      })
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
