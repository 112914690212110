<template>
    <div class="Flexboxcontainer">
        <div class="content">

            <div class="updateCard">
                <v-card v-model="updateCard" v-if="updateCard == true" style="background-color: #1976D2;" class="ma-2 pa-3" flat>
                        <v-toolbar dark dense flat style="background-color: #1976D2" >
                            <v-card-title style="background-color: #1976D2;" dense> 
                                <v-icon dark class="pr-2">mdi-alert-outline</v-icon>
                                <v-toolbar-title @dblclick="addLogsDialog = true" style="color: white;"><b>{{$t('Header.updateNotice')}}</b></v-toolbar-title>
                            </v-card-title>
                            <v-spacer></v-spacer>
                            <v-btn @click="updateCardClose()" icon><v-icon>mdi-backspace-reverse-outline</v-icon></v-btn>
                        </v-toolbar>
                        <v-card id="vCard" elevelation="5" class="overflow-auto" style="background-color: #1976D2;" max-width="385px" :height="tableHeight" flat>
                            <v-card elevelation="5" v-for="(group, version) in versionGroups" :key="version" class="pa-2 ma-2 overflow-y: auto">
                                <v-chip class="none" small color="primary" label outlined>Version {{ group[0].Version }}</v-chip>
                                <b> - {{ group[0].DeployedDate }}</b>
                                <v-chip v-if="isWeekRange(group[0].DeployedDate)" outlined label small color="#389DCA" class="shineEffect ml-2">New</v-chip>
                                <br><br>
                                <v-divider class="mt-n4"></v-divider>
                                <ul class="list-unstyled mt-2">
                                    <li v-for="log in group" :key="log.id" class="text-muted ml-2" style="text-align: left;">
                                    {{ log.ContentofRequest }}
                                    </li>
                                </ul>
                            </v-card>
                        </v-card> 
                </v-card>
            </div>
            <v-card class="ma-2" flat>
                <v-dialog width="350px" v-model="addLogsDialog" persistent>
                            <v-toolbar dark dense flat style="background-color: #1976D2" >
                                <v-card-title dense> 
                                    <v-icon dark class="pr-1">mdi-alert-outline</v-icon>
                                    <v-toolbar-title style="color: white;">Add Logs</v-toolbar-title>
                                </v-card-title>
                                <v-spacer></v-spacer>
                                <v-btn @click="addLogsDialog = false" icon><v-icon>mdi-close</v-icon></v-btn>
                            </v-toolbar>
                            <v-card>
                                <v-text-field 
                                class="pr-5 pl-5 pt-7" 
                                label="Version" 
                                persistent-placeholder 
                                clearable 
                                dense
                                outlined
                                v-model="obj.Version"
                            ></v-text-field>
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        persistent-placeholder 
                                        class="pr-5 pl-5" 
                                        v-model="obj.DateRequested"
                                        label="Date Requested"
                                        prepend-inner-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        v-bind="attrs"
                                        v-on="on"
                                        clearable
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    no-title
                                    scrollable
                                    v-model="obj.DateRequested"
                                    @input="menu = false"
                                ></v-date-picker>
                            </v-menu>
                            <v-textarea
                                class="pr-5 pl-5" 
                                @keydown.enter.prevent
                                rows="1"
                                auto-grow
                                no-resize
                                clearable
                                outlined
                                dense
                                persistent-placeholder
                                label="Remarks"
                                v-model="obj.ContentofRequest"
                            ></v-textarea>
                            <v-textarea
                                class="pr-5 pl-5" 
                                @keydown.enter.prevent
                                rows="1"
                                auto-grow
                                no-resize
                                clearable
                                outlined
                                dense
                                persistent-placeholder
                                label="RemarksJPN(Optional)"
                                v-model="obj.ContentofRequestJap"
                            ></v-textarea>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn :disabled="!obj.Version || !obj.ContentofRequest" @click="insert()" color="primary">Add</v-btn>
                            </v-card-actions>
                            </v-card>
                </v-dialog>
            </v-card>


            <v-hover v-slot="{ hover }">
                <div class="update">
                    <v-card elevation="5" class="mt-6 mr-n2" flat v-model="updateLogs" v-show="updateLogs == true">
                        <v-btn @click="updateCardOpen()" style="background-color: #1976D2;" dense> 
                        <v-icon dark class="pr-1" color="white">mdi-alert-outline</v-icon>
                            <v-expand-transition>
                                <div
                                    v-if="hover"
                                    class="d-flex transition-fast-in-fast-out v-card--reveal text-h2 white--text"
                                    style="height: 100%; width:8rem"
                                >
                                    <v-toolbar-title style="color: white;"><h5>Update Notice !</h5></v-toolbar-title>
                                </div>
                            </v-expand-transition>
                        </v-btn>
                    </v-card>
                </div>
            </v-hover>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';

export default {
    data: () => ({
        // deployment: 'test',
        menu: false,
        obj: {},
        addLogsDialog: false,
        loading: false,
        updateLogs:false,
        howtoUse:true,
        updateCard: true,
        versionLogs: [],
        tableHeight: '',
        toDecrease: 0.05,
        newUpdate: [
            {
                id : 36,
                ContentofRequest: 'Change customer name display to surname',
                DeployedDate: 'Jun 27, 2024',
                Version: '2.1.5'
            },
            {
                id : 35,
                ContentofRequest: 'Create condition for Kakou irai Send data',
                DeployedDate: 'Jun 27, 2024',
                Version: '2.1.5'
            },
            {
                id : 34,
                ContentofRequest: 'Add session logout function',
                DeployedDate: 'Jun 21, 2024',
                Version: '2.1.3'
            },
            {
                id : 33,
                ContentofRequest: 'Add email validation in login',
                DeployedDate: 'Jun 21, 2024',
                Version: '2.1.3'
            },
            {
                id : 32,
                ContentofRequest: 'Fix AR status and add stop plan status',
                DeployedDate: 'Jun 20, 2024',
                Version: '2.0.1'
            },
            {
                id : 27,
                ContentofRequest: 'Additional column in Order Schedule',
                DeployedDate: 'Jun 18, 2024',
                Version: '2.0.0'
            },
            {
                id : 28,
                ContentofRequest: 'Additional column for Application Request Plan no.',
                DeployedDate: 'Jun 18, 2024',
                Version: '2.0.0'
            },
            {
                id : 29,
                ContentofRequest: 'Major changes in data gathering due to additional conditions',
                DeployedDate: 'Jun 18, 2024',
                Version: '2.0.0'
            },
            {
                id : 30,
                ContentofRequest: 'Change login credentials using Document Search System(DSS) accounts',
                DeployedDate: 'Jun 18, 2024',
                Version: '2.0.0'
            },
            {
                id : 31,
                ContentofRequest: 'Additional change password function',
                DeployedDate: 'Jun 18, 2024',
                Version: '2.0.0'
            },
        ]
    }),

    methods: {
        insert() {
            this.addLogsDialog = false
            axios.get(`${this.aws2}insertLogs`,this.obj, this.apiKey2).then(res => {
            Swal.fire({
                title: res.data ? 'Success' : 'Error',
                text: res.data ? 'Insert Successfully' : res.data,
                icon: res.data ? 'success' : 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#28a745',
                });
            })
        },
        loadData() {
            axios.get(`${this.aws2}version`, this.apiKey2).then(res => {
            const formattedData = res.data.map(record => {
                const deployedDate = new Date(record.DeployedDate);
                const formattedDate = deployedDate.toLocaleString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric'
                });
                record.DeployedDate = formattedDate.replace(/(?<=\d)(st|nd|rd|th)/, ''); // Remove ordinal suffixes
                return record;
            });

            formattedData.sort((a, b) => new Date(b.DeployedDate) - new Date(a.DeployedDate));
            this.versionLogs = formattedData;
            this.versionLogs.unshift(...this.newUpdate)
            });
            this.$nextTick(() => {
                this.getTableHeight()
            });
        },
        updateCardOpen() {
            this.updateCard = true  
            this.updateLogs = false
            this.$nextTick(() => {
                this.getTableHeight()
            });
        },
        updateCardClose() {
            this.updateCard = false
            this.updateLogs = true
        },
        getTableHeight() {
            
            setTimeout(() => {
                const simpleTable = document.querySelector('#vCard');
                if (simpleTable)
                {
                const distanceFromTop = simpleTable.getBoundingClientRect().top;
                const tableHeightToBottom = window.innerHeight - distanceFromTop
                this.tableHeight = tableHeightToBottom - (tableHeightToBottom * this.toDecrease);
                }
            }, 500);
        },
        isWeekRange(date) {
            if (!date) {
            return false;
            }

            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const oneWeekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
            oneWeekAgo.setHours(0, 0, 0, 0);

            const dateFormat = new Date(date)
            dateFormat.setHours(0, 0, 0, 0);

            return dateFormat >= oneWeekAgo;
        }
    },

    computed: {
        versionGroups() {
            const groups = {};
            this.versionLogs.forEach(item => {
            if (!groups[item.Version]) {
                groups[item.Version] = [];
            }
            groups[item.Version].push(item);
            });
            return Object.values(groups);
        },
    },

    mounted() {
        this.loadData()
        window.addEventListener('resize', () =>{
            this.getTableHeight()
        })
    },

    watch: {
    },
};
</script>

<style>
.content {
    position: relative;
    width: 30%;
    top: 25rem;
    text-align: center;
    font-size: 18px;
    right: 15rem;
}
.Flexboxcontainer {
    display: flex;
    flex-wrap: wrap;
}
.updateCard {
    position: fixed;
    right:0;
    top: 3rem
}
.howtoCard {
    position: fixed;
    right:0;
    left:50%;
    top: 3rem
}
.howto {
    position: fixed;
    right:0;
    top: 6rem
}
.update {
    position: fixed;
    right:0;
    top: 3rem
}
.none {
    pointer-events: none;
}
.shineEffect {
  position: relative;
  overflow: hidden;
}

.shineEffect::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, #389DCA, #ffffff, #389DCA); /* Adjust colors as needed */
  opacity: 0;
  transform: rotate(45deg) translateX(-100%);
  animation: shine 1.5s infinite;
}

@keyframes shine {
  0% {
    opacity: 0;
    transform: rotate(45deg) translateX(-100%);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translateX(100%);
  }
}
</style>
