import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins:[createPersistedState({storage: window.sessionStorage})],
  state: {
    kiddUser: null,
    plannersData: null,
    constructionData: null,
    scroll: { scrollTop: 0, scrollLeft: 0 },

  },
  getters: {
  },
  mutations: {
    'STORE_kiddUser':(state, newState)=>{
      state.kiddUser = newState
    },
    'STORE_plannersData':(state, newState)=>{
      state.plannersData = newState
    },
    'STORE_constructionData':(state, newState)=>{
      state.constructionData = newState
    },
    'SET_scrollPosition':(state, { scrollTop, scrollLeft })=>{
      state.scroll = { scrollTop, scrollLeft };
    }
  },
  actions: {
    },
  modules: {
  }
})
