import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import i18n from './vue-i18n'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import myMixin from './plugins/myMixins' 
Vue.config.productionTip = false
Vue.use(myMixin)
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    let timer;
    const resetTimer = () =>{
      if(timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        this.logout()
      }, 120*60*1000);
    }

    window.addEventListener('mousemove', resetTimer)
    window.addEventListener('keydown', resetTimer)
    resetTimer()
  },
  methods: {
    logout() {
      this.$store.commit("STORE_kiddUser", null);
      this.$store.commit("STORE_plannersData", null);
      this.$store.commit("STORE_constructionData", null);
      this.$store.commit("SET_scrollPosition", {scrollTop: 0, scrollLeft: 0});
      this.$router.push("/login");
    }
  },
}).$mount('#app')
