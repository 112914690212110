<template>
    <v-app>

        <v-app-bar app color="primary" dark dense ref="appBar" v-if="$store.state.kiddUser">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <span :style="{textWrap: 'wrap', fontSize: fonSize.h3}">{{ getRouteTitle }}</span>
            <v-spacer/>
            <v-divider vertical/> 
            <div class="mr-n5">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" x-large text v-on="on">
                            <v-icon :style="{textWrap: 'wrap', fontSize: fonSize.h2}">mdi-account</v-icon>
                            <span class="ml-2" :style="{textWrap: 'wrap', fontSize: fonSize.h4}">{{`${userData.Name ?? ''}`}}</span>
                            <v-icon :style="{textWrap: 'wrap', fontSize: fonSize.h2}">mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="updatePasswordDialog = true">
                            <v-list-item-title>
                            <div class="d-flex align-center">
                                <div>
                                    <v-icon class="mr-2" medium>mdi-lock</v-icon>
                                    <span>{{$t('Account.changePassword')}}</span>
                                </div>
                            </div>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="logout()">
                            <v-list-item-title>
                            <div class="d-flex align-center">
                                <div>
                                    <v-icon class="mr-2" medium>mdi-logout</v-icon>
                                    <span>{{$t('Account.logout')}}</span>
                                </div>
                            </div>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>

        <v-navigation-drawer fixed app dark width="auto" v-model="drawer" style="background-color:#1976D2" v-click-outside="onClickOutside" v-if="$store.state.kiddUser">
            <v-list class="py-0" dense>
                <v-list-item style="background-color:#1976D2" class="custom-elevation">
                    <v-list-item-content  class="white--text" >
                    <v-list-item-title class="pt-3">
                        <span :style="{textWrap: 'wrap', fontSize: fonSize.h3}" style="font-weight:light">HRD Schedule Ver.<sup>{{version.Version}}</sup></span>
                    </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-for="item in getLinks" :to="item.to" :key="item.title" link @click="drawer = false">
                    <v-list-item-icon>
                        <v-icon :style="{textWrap: 'wrap', fontSize: fonSize.h2}">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        <span :style="{textWrap: 'wrap', fontSize: fonSize.h4}">{{ item.title }}</span>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <router-view />
        </v-main>

        <v-dialog v-model="updatePasswordDialog" max-width="400px" persistent>
            <v-card outlined>
                <v-toolbar flat height="48" style="background-color:#1976D2">
                    <h3 class="ml-2" style="text-wrap: nowrap; color: white">{{$t('Account.changePassword')}}</h3>
                    
                    <v-spacer></v-spacer>
                    <v-btn icon size="30">
                        <v-icon color="white" @click="closeDialog()">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card flat class="pa-2" style="overflow-y:auto;">
                    <v-row no-gutters>
                        <v-col>
                            <v-card-text class="text--primary">
                                <div class="text-center">
                                    <v-text-field 
                                        v-if="!userData.Default"
                                        v-model="update.oldPassword" 
                                        :label="$t('Account.currentPassword')"  
                                        prepend-inner-icon="mdi-lock"
                                        :append-icon="showOldPassword == true ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showOldPassword == true ? 'text' : 'password'"
                                        @click:append="showOldPassword = !showOldPassword"
                                        tabindex="1"
                                        clearable
                                    ></v-text-field>
                                    <v-text-field 
                                        ref="newPasswordField"
                                        v-model="update.newPassword" 
                                        :label="$t('Account.newPassword')"  
                                        prepend-inner-icon="mdi-lock"
                                        :append-icon="showNewPassword == true ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showNewPassword == true ? 'text' : 'password'"
                                        @click:append="showNewPassword = !showNewPassword"
                                        tabindex="2"
                                        clearable
                                    ></v-text-field>
                                    <v-text-field 
                                        ref="confirmPasswordField"
                                        v-model="update.confirmPassword" 
                                        :label="$t('Account.confirmPassword')"  
                                        :rules="[validatePasswordMatch]"
                                        prepend-inner-icon="mdi-lock"
                                        :append-icon="showConfirmPassword == true ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showConfirmPassword == true ? 'text' : 'password'"
                                        @click:append="showConfirmPassword = !showConfirmPassword"
                                        tabindex="3"
                                        clearable
                                        @keyup.enter="updateSubmit(update)"
                                    ></v-text-field>
                                    <div class="text-right mt-4">
                                    <v-btn outlined color="primary" 
                                        :disabled="!update.oldPassword || !update.newPassword || !update.confirmPassword || enabledButton != true" 
                                        tabindex="4"
                                        clearable
                                        @click="updateSubmit(update)"
                                    >{{$t('Account.update')}}</v-btn>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import DataTransform from './assets/class/DataTransform.js'
import Swal from "sweetalert2";
import axios from 'axios';
import md5 from 'md5'
export default {
    name: "App",

    data: () => ({
        dataTransform: new DataTransform(),
        enabledButton: false,
        showOldPassword:'',
        showNewPassword:'',
        showConfirmPassword:'',
        updatePasswordDialog: false,
        update: {},
        drawer: false,
        items: [
                { title: "ホームページ", icon: "mdi-home", to: "/" },
                { title: "申請関連図書及び各種部材の各種日程確認", icon: "mdi-account-hard-hat-outline", to: "/constructiondetails" },
                { title: "担当物件進捗状況一覧", icon: "mdi-table-large", to: "/planners"}
        ],
        // items2: [
        //         { title: "ホームページ", icon: "mdi-home", to: "/" },
        //         { title: "申請関連図書及び各種部材の各種日程確認", icon: "mdi-account-hard-hat-outline", to: "/constructiondetails" },
        // ],
        title: 'HRD Schedule Ver.',
        version: [{Version: '2.1.5'}],
        userData: {},
        fonSize: {
            h1: 32 - (window.innerHeight / window.innerWidth * 2.8) + 'px',
            h2: 24 - (window.innerHeight / window.innerWidth * 2.8) + 'px',
            h3: 18.72 - (window.innerHeight / window.innerWidth * 2.8) + 'px',
            h4: 16 - (window.innerHeight / window.innerWidth * 2.8) + 'px',
            h5: 13.28 - (window.innerHeight / window.innerWidth * 2.8) + 'px',
            h6: 10.72 - (window.innerHeight / window.innerWidth * 2.8) + 'px',
        }
    }),

    methods: {
        closeDialog(){
            this.updatePasswordDialog = false
            this.update = {}
            if(this.$refs.confirmPasswordField) {
                this.$refs.confirmPasswordField.reset()
            }
        }, 
        validatePasswordMatch(value) {
            if(value){
                if (value === this.update.newPassword) {
                    if(this.update.newPassword === 'ichijo' || this.update.newPassword === 'password'){
                        this.enabledButton = false
                        return 'Password does not recommended';
                    }else{
                        this.enabledButton = true
                        return true;
                    }
                } else {
                    this.enabledButton = false
                    return 'Password does not match';
                }
            }else{
                this.enabledButton = false
                return false
            }
        },
        updateSubmit(obj) {
            if (obj) {
                let userData = {
                    oldPassword: obj.oldPassword ? md5(obj.oldPassword) : md5('ichijo'),
                    confirmPassword: md5(obj.confirmPassword),
                    Mail_Address: this.userData.Mail_Address || this.userData.MailAddress
                }
                axios.post(`${this.aws2}/updateKiddCredentials`, userData, this.apiKey2).then(response => {
                    if (response.data){
                        Swal.fire({
                            toast: true,
                            position: "top-end",
                            icon: "success",
                            title: `パスワード変更成功`,
                            showConfirmButton: false,
                            timer: 2200,
                        });
                        this.closeDialog()
                        this.$store.commit("STORE_kiddUser", null);
                        this.$router.push("/login");
                    }
                }).catch((error) => {
                    if(error.response.data === 'Old password does not match.'){
                        Swal.fire({
                            toast: true,
                            position: "top-end",
                            icon: "error",
                            title: `古いパスワードが間違っています。`,
                            showConfirmButton: false,
                            timer: 2200,
                        });
                    }
                    this.loading = false;
                });
            }
        },
        clearSessionStorage(){
            Promise.all([
                this.$store.commit("STORE_kiddUser", null),
                this.$store.commit("STORE_plannersData", null),
                this.$store.commit("STORE_constructionData", null),
                this.$store.commit("SET_scrollPosition", {scrollTop: 0, scrollLeft: 0}),
                this.$router.push("/login")
            ])
        },
        loadData() {
            axios.get(`${this.aws2}version`, this.apiKey2).then(res => {
                let latestVersion = [];
                latestVersion = this.version[0].Version ? this.version[0] : res.data[res.data.length - 1]
                this.version = latestVersion
                if (this.$store.state.kiddUser) {
                    this.userData = this.dataTransform.toDecrypt(this.$store.state.kiddUser)
                    // console.log(this.userData);
                    if(this.userData && !this.userData.pageReloaded){
                        Swal.fire({
                            // title: "<strong>New Update!</strong>",
                            // html: `You will be <b>logged out</b>. Please use your DSS account during the next login.`,
                            title: "<strong>更新通知</strong>",
                            html: `ログアウトされます。次回のログイン時にDSS(Document Search System)アカウントを使用してください。`,
                            icon: "info",
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.clearSessionStorage()
                            }
                        });
                    }
                }
            })
        },
        onClickOutside(event) {
            const clickedElement = event.target;
            if (this.$refs.appBar) {
                const isInsideAppBar = this.$refs.appBar.$el.contains(clickedElement);
                if (!isInsideAppBar) {
                this.drawer = false;
                }
            }
        },
        logout() {
            Swal.fire({
                title: "このアカウントからログアウトしますか？",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ログアウト",
                cancelButtonText: "キャンセル",
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        toast: true,
                        position: "top-end",
                        icon: "success",
                        title: "アカウントは正常にログアウトされました",
                        showConfirmButton: false,
                        timer: 2200,
                    });
                    this.clearSessionStorage()
                }
            });
        },
    },
    computed: {
        getLinks() {
            if (!this.userData.Emp_code) {
                let excludedPage = this.items.filter((val) => val.to != '/planners')
                return excludedPage
            } else {
                return this.items
            }
        },
        getRouteTitle(){
            const matchedItem = this.items.find(val => val.to === this.$route.path);
            return matchedItem ? matchedItem.title : 'ホームページ';
        }
    },
    mounted() {
        this.loadData()
        window.addEventListener('resize', () => {
            this.fonSize.h1 = 32 - (window.innerHeight / window.innerWidth * 2.8) + 'px'
            this.fonSize.h2 = 24 - (window.innerHeight / window.innerWidth * 2.8) + 'px'
            this.fonSize.h3 = 18.72 - (window.innerHeight / window.innerWidth * 2.8) + 'px'
            this.fonSize.h4 = 16 - (window.innerHeight / window.innerWidth * 2.8) + 'px'
            this.fonSize.h5 = 13.28 - (window.innerHeight / window.innerWidth * 2.8) + 'px'
            this.fonSize.h6 = 10.72 - (window.innerHeight / window.innerWidth * 2.8) + 'px'
        })
    },
    watch: {
        'update.newPassword': function() {
            this.$refs.confirmPasswordField.validate();
        },
        'update.confirmPassword': function() {
            this.$refs.confirmPasswordField.validate();
        }
    },
};
</script>

<style>
.custom-elevation {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
</style>
