export default class DataTransform{
  constructor(){
  }
  
  toEncrypt(data){
    const jsonString = JSON.stringify(data);

    const encoder = new TextEncoder();
    const encodedBytes = encoder.encode(jsonString);

    const encryptedText = btoa(String.fromCharCode(...encodedBytes));

    return encryptedText;
  }

  toDecrypt(data){
    const decodedBytes = atob(data);

    const decoder = new TextDecoder();
    const decodedString = decoder.decode(Uint8Array.from(decodedBytes, c => c.charCodeAt(0)));

    const decryptedText = JSON.parse(decodedString);

    return decryptedText
  }
}

