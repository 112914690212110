import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "../store";
Vue.use(VueRouter)
// const baseURL = '/V.' + store.state.storeVersion;

const router = new VueRouter({
  mode: 'history',
  // base: baseURL,
  routes: [
    {
      path: '*',
      redirect: { name: 'home' }
    },
    {
      path: '/home',
      name: 'home',
      component: HomeView
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: () => import(/* webpackChunkName: "about" */ '../views/LoginPage.vue')
    },
    {
      path: '/planners',
      name: 'planners',
      component: () => import(/* webpackChunkName: "about" */ '../views/PlannersView.vue')
    },
    {
      path: '/constructiondetails',
      name: 'constructiondetails',
      component: () => import(/* webpackChunkName: "about" */ '../views/ConstructionView.vue')
    },
    {
      path: '/import',
      name: 'import',
      component: () => import(/* webpackChunkName: "about" */ '../views/CsvImport.vue')
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    if (store.state.kiddUser != null) {
      next('/')
    }
    else { next() }
  } else {
    if (store.state.kiddUser != null) {
      next()
    } else {
      next('/login')
    }
  }
});


export default router
