const myMixins={
    install(Vue) {
        Vue.mixin({
            data() {
                return {
                    deployment: 'test',
                    api: process.env.VUE_APP_URL,
                    aws: process.env.VUE_APP_URL_AWS,
                    apiKey: {
                        headers: {
                          "Content-Type": "application/json",
                          "x-api-key": process.env.VUE_APP_API_KEY,
                        }
                      },
                    aws2:process.env.VUE_APP_KIDD_URL_AWS,
                    apiKey2: {
                        headers: {
                          "Content-Type": "application/json",
                          "x-api-key": process.env.VUE_APP_KIDD_URL_KEY,
                          // "token": process.env.VUE_APP_PLANNET_API_TOKEN
                        }
                      },
                    gcUrl:process.env.VUE_APP_GC_URL_AWS,
                    GCAPkikey: {
                        headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.VUE_APP_GC_API_KEY,
                        }
                    },
                    DSS_api:process.env.VUE_APP_DSS_API,
                    DSS_apiKey: {
                      headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.VUE_APP_DSS_API_KEY,
                      }
                    },
                }
            },//end of data
            methods: {
             
            },// end of methods
            mounted () {
            
            },// end of mounted
            computed: {
            
            },//end of computed
        })
    }



}
export default myMixins